export const createCordialContact = async (
  email: string,
  code: string,
  getAuthenticatedHeaders: () => Promise<Headers>
) => {
  return await fetch(
    `${process.env["REACT_APP_PANGAEA_BASE_URL"] ||
      "http://localhost:7777/v1/"}cordial`,
    {
      method: "POST",
      headers: await getAuthenticatedHeaders(),
      body: JSON.stringify({
        channels: { email: { address: email } },
        promo_code: code,
      }),
    }
  );
};

export const updateCordialContact = async (
  email: string,
  code: string,
  getAuthenticatedHeaders: () => Promise<Headers>
) => {
  return await fetch(
    `${process.env["REACT_APP_PANGAEA_BASE_URL"] ||
      "http://localhost:7777/v1/"}cordial`,
    {
      method: "PUT",
      headers: await getAuthenticatedHeaders(),
      body: JSON.stringify({
        channels: { email: { address: email } },
        promo_code: code,
      }),
    }
  );
};
