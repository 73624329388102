import {
  createCordialContact,
  updateCordialContact,
} from "./handleCordialContact";
import { getAuthenticatedHeaders } from "./getAuthenticationHeaders";
import Cookies from "js-cookie";

export const emailCordialSubmit = async (email: string) => {
  const checkEmailResponse = await fetch(
    `${process.env["REACT_APP_PANGAEA_BASE_URL"] ||
      "http://localhost:7777/v1/"}wordpress/email-first-seen/${email}`,
    {
      method: "GET",
    }
  );

  const { emailProvided } = await checkEmailResponse.json();

  if (checkEmailResponse.ok && !emailProvided) {
    const response = await fetch(
      `${process.env["REACT_APP_PANGAEA_BASE_URL"] ||
        "http://localhost:7777/v1/"}promotional-codes/single-use`,
      {
        method: "POST",
        headers: await getAuthenticatedHeaders(),
        body: JSON.stringify({ email, startTime: new Date() }),
      }
    );

    const data = await response.json();

    if (response.ok) {
      const codeData = {
        code: data.code,
        email: data.email,
        endTime: data.endTime,
        status: data.status,
      };

      let cordialResponse = await createCordialContact(
        email,
        codeData.code,
        getAuthenticatedHeaders
      );

      if (!cordialResponse.ok) {
        cordialResponse = await updateCordialContact(
          email,
          codeData.code,
          getAuthenticatedHeaders
        );
      }
      Cookies.set("yzpcode", JSON.stringify(codeData), {
        expires: 7,
        domain: "yardzen.com",
      });
      Cookies.remove("yzhpb", { domain: "yardzen.com" });
    }
  } else {
    Cookies.set("yzhpb", "true", { domain: "yardzen.com" });
  }
};
