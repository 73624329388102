import { Box, makeStyles, Theme, useTheme } from "@material-ui/core";
import { GenericLoadingSpinner } from "@yardzen-inc/react-common";
import React from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useMaps } from "./util/useMaps";

export type HandleAddressSelectionFunc = (selection: {
  value: any;
  label: any;
}) => Promise<void>;

interface PlacesAddressInputProps {
  handleAddressSelection: HandleAddressSelectionFunc;
  defaultAddress?: string | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  addressInputContainer: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "500px",
    },
  },
  addressInputLabel: {
    display: "none",
  },
}));

export const PlacesAddressInput = ({
  handleAddressSelection,
  defaultAddress = "",
}: PlacesAddressInputProps) => {
  const { addressInputContainer, addressInputLabel } = useStyles();
  const theme = useTheme();

  const google = useMaps();

  // This will prevent the error boundary from being shown if we're trying
  // to render but the google maps api has not yet loaded. The classname
  // will help prevent content from shifting.
  if (typeof google !== "object" || typeof google?.maps !== "object") {
    return (
      <Box className={addressInputContainer}>
        <GenericLoadingSpinner />
      </Box>
    );
  }

  return (
    <>
      {
        // Hidden element that exists purely to increase accessibility.
      }
      <label className={addressInputLabel} id="address-input-label">
        Enter your home address
      </label>
      <GooglePlacesAutocomplete
        selectProps={{
          onChange: handleAddressSelection,
          "aria-labelledby": "where-is-your-space address-input-label",
          className: addressInputContainer,
          styles: {
            control: (baseStyles: any, state: any) => ({
              ...baseStyles,
              fontSize: "16px",
              padding: "8px",
              borderColor: state.isFocused
                ? theme.palette.primary.main
                : baseStyles.borderColor,
              boxShadow: state.isFocused
                ? `0 0 0 1px ${theme.palette.primary.main}`
                : "none",
              "&:hover": {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
              },
            }),
            menuList: (baseStyles: any) => ({
              ...baseStyles,
              textAlign: "left",
            }),
          },
          // This will remove the dropdown indicators which we want removed
          // to reduce user confusion.
          components: {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          },
          placeholder: "Enter your home address...",
          defaultInputValue: defaultAddress ?? "",
          id: "address-input",
        }}
      />
    </>
  );
};
