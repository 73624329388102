import { Box, BoxProps } from "@material-ui/core";
import * as React from "react";
import { SquareModalBase } from "..";
import { IAddressObject } from "../../util/functions/parseGeocodeLocationToAddress";
import HelpIconWithText from "../buttons/HelpIconWithText";
import {
  HandleAddressSelectionFunc,
  PlacesAddressInput,
} from "../map/PlacesAddressInput";
import AddressManualEntryForm from "./AddressManualEntryForm";

interface IPlacesAddressFormProps extends BoxProps {
  defaultAddress?: string;
  onSubmitAddress: (address: IAddressObject) => Promise<any>;
  handleAddressSelection: HandleAddressSelectionFunc;
}

export const PlacesAddressForm: React.FunctionComponent<IPlacesAddressFormProps> = ({
  defaultAddress,
  onSubmitAddress,
  handleAddressSelection,
}) => {
  const [helpOpen, setHelpOpen] = React.useState<boolean>(false);

  return (
    <Box width="100%">
      <Box
        width="100%"
        pt={4}
        px={[1, 4]}
        display="flex"
        justifyContent="center"
      >
        <PlacesAddressInput
          handleAddressSelection={handleAddressSelection}
          defaultAddress={defaultAddress}
        />
      </Box>
      <Box
        overflow="hidden"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box pt={3}>
          <HelpIconWithText
            onClick={() => setHelpOpen(true)}
            typographyProps={{ color: "textSecondary" }}
          >
            My home address isn't showing up.
          </HelpIconWithText>
        </Box>
      </Box>
      <SquareModalBase
        open={helpOpen}
        onClose={() => setHelpOpen(false)}
        keepMounted
      >
        <AddressManualEntryForm
          setOpenHelp={setHelpOpen}
          onSubmit={address => onSubmitAddress(address)}
        />
      </SquareModalBase>
    </Box>
  );
};
