import firebase from "firebase/compat/app";

export const getAuthenticatedHeaders = async () => {
  const headers = new Headers();
  headers.set(
    "Authorization",
    `Bearer ${await firebase.auth()?.currentUser?.getIdToken()}`
  );
  headers.set("Content-Type", "application/json");
  headers.set("Accept", "application/json");

  return headers;
};
