import * as React from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import "firebase/auth";
import "firebase/firestore";
import { YZButton, YZTypography } from "@yardzen-inc/react-common";
import { HowDidYouHearAboutYZ } from "../../components/utility";
import { useDataLayer } from "../../data/DataLayerProvider";
import { useSegment } from "../../util/Segment";
import {
  SegmentFlows,
  SegmentForms,
  SegmentInputTypes,
} from "../../util/Segment/constants";
import usePrevious from "../../util/hooks/usePrevious";
import DesignProfileLayout from "../../components/designProfile/DesignProfileLayout";
import { DesignProfileInput } from "../../components/designProfile/DesignProfileInput";
import { Page } from "../../types/DesignProfile";
import formatPhoneNumber from "../../util/functions/formatPhoneNumber";
import { useHistory } from "react-router";
import { useDesignProfileCtx } from "../../components/designProfile";
import { handleEmailPhoneNextClick } from "./util/handleEmailPhoneNextClick";
import { handleEmailPhoneSubmit } from "./util/handleEmailPhoneSubmit";
import { UserCtx } from "../../util";
import { isPhoneNumberFormatable } from "./util/isPhoneNumberFormatable";
import { DesignProfileRouteAccess } from "../../components/designProfile/DesignProfileRouteAccess";
import { ATTENTIVE_URLS } from "./constants/attentiveUrls";
import { useTreatment } from "@yardzen-inc/react-split";
import { MARKETING_PHONE_COLLECTION_DP } from "../../util/Split/splitTreatments";
import Cookies from "js-cookie";
import { emailCordialSubmit } from "./util/emailCordialSubmit";

export interface DesignProfileEmailPhoneInputPageProps {
  onNext: () => void;
  onPrevious: () => void;
  page: Page;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "100vh",
    maxWidth: "500px",
    padding: "0 20px",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(12),
    },
  },
  formContainer: {
    maxWidth: "400px",
    margin: "0 auto",
  },
  linkText: {
    cursor: "pointer",
    color: "#000",
    textDecoration: "underline",
    fontWeight: 500,
  },
  blackButton: {
    color: "white",
    backgroundColor: "#323334",
    "&:hover": {
      backgroundColor: "#323334",
    },
    "& p": {
      fontWeight: "bold",
    },
  },
}));

export const DesignProfileEmailPhoneInputPage: React.FC<DesignProfileEmailPhoneInputPageProps> = ({
  onNext,
  onPrevious,
  page,
}) => {
  const defaultEmail = Cookies.get("yzQuizEmail") || "";

  const segment = useSegment();
  const dl = useDataLayer();
  const { designProfile, updateDesignProfile } = useDesignProfileCtx();
  const [email, setEmail] = React.useState<string>(defaultEmail);
  const [phone, setPhone] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [referralSource, setReferralSource] = React.useState<string>("");
  const previousReferralSource = usePrevious(referralSource, true);
  const [errorText, setErrorText] = React.useState<string>("");
  const [phoneErrorText, setPhoneErrorText] = React.useState<string>("");
  const history = useHistory();
  const userCtx = React.useContext(UserCtx);
  const userId = !!userCtx ? userCtx.uid : undefined;

  const classes = useStyles();

  const showMarketingPhoneCollectionSplit = useTreatment(
    MARKETING_PHONE_COLLECTION_DP
  );

  const handleReferralChange = () => {
    if (referralSource && referralSource !== previousReferralSource) {
      segment.trackFormFieldFilled({
        field_name: "Referral Source",
        flow_name: SegmentFlows.DESIGN_PROFILE,
        input_type: SegmentInputTypes.SELECT,
        form_name: SegmentForms.DESIGN_PROFILE_EMAIL,
        option_selected: referralSource,
      });
    }
  };

  // TODO: remove disable comment and fix warning next time this hook is updated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(handleReferralChange, [referralSource, segment]);

  const submit = () => {
    return handleEmailPhoneSubmit({
      userId,
      onNext,
      referralSource,
      designProfile,
      updateDesignProfile,
      email,
      phone,
    });
  };

  const handleClick = async (e: React.FormEvent) => {
    emailCordialSubmit(email);

    await handleEmailPhoneNextClick({
      e,
      segment,
      loading,
      setLoading,
      setErrorText,
      setPhoneErrorText,
      history,
      submit,
      email,
      phone,
      referralSource,
      dl,
      nextPath: `/design-profile/${page.next}`,
    });
  };

  const handleKeyPress = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      await handleClick(e);
    }
  };

  return (
    <DesignProfileRouteAccess>
      <Box mt={6} className={classes.root}>
        <DesignProfileLayout title={page.title} subtitle={page.subtitle}>
          <Box
            component="form"
            onSubmit={handleClick}
            className={classes.formContainer}
            pt={4}
          >
            <DesignProfileInput
              handleChange={e => setEmail(e.target.value)}
              handleKeyPress={e => void handleKeyPress(e)}
              title="We'll save and email your recommendations"
              value={email}
              label={"Email"}
              helperText=""
              errorText={errorText}
              segmentFields={{
                flowName: SegmentFlows.DESIGN_PROFILE,
                formName: SegmentForms.DESIGN_PROFILE_EMAIL,
                fieldName: "Email",
                inputType: SegmentInputTypes.TEXT,
              }}
              setFocused={() => {}}
              titleTextAlign="left"
            />
            {showMarketingPhoneCollectionSplit && (
              <>
                <div style={{ marginTop: 15 }}>
                  <DesignProfileInput
                    handleChange={e => {
                      const value = e.target.value;
                      isPhoneNumberFormatable(value) &&
                        setPhone(formatPhoneNumber(value) || value);
                    }}
                    handleKeyPress={e => void handleKeyPress(e)}
                    title="Enter your phone number to receive design tips, inspiration, and offers via text. (Optional)"
                    value={phone}
                    label={"Mobile Phone Number"}
                    helperText=""
                    errorText={phoneErrorText}
                    segmentFields={{
                      flowName: SegmentFlows.DESIGN_PROFILE,
                      formName: SegmentForms.DESIGN_PROFILE_PHONE,
                      fieldName: "Phone",
                      inputType: SegmentInputTypes.TEXT,
                    }}
                    setFocused={() => {}}
                    titleTextAlign="left"
                  />
                </div>
                <Box mt={1} textAlign="left">
                  <YZTypography variant="caption">
                    By entering your phone number and submitting this form, you
                    agree to receive recurring automated promotional and
                    personalized marketing text messages from Yardzen. Reply
                    HELP for help and STOP to cancel. Message frequency vary.
                    Message and data rates may apply. View{" "}
                    <a
                      className={classes.linkText}
                      href={ATTENTIVE_URLS.TERMS}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms
                    </a>{" "}
                    &{" "}
                    <a
                      className={classes.linkText}
                      href={ATTENTIVE_URLS.PRIVACY}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                    .
                  </YZTypography>
                </Box>
              </>
            )}
            <div style={{ marginTop: 25 }}>
              <HowDidYouHearAboutYZ
                selectedOption={referralSource}
                setSelectedOption={setReferralSource}
                error={false}
              />
            </div>
          </Box>
          <Box
            style={{ transition: "margin 200ms" }}
            display="flex"
            flexDirection="row"
            justifyContent="center"
            py={4}
            maxWidth="99vw"
            marginX="auto"
          >
            <YZButton
              disabled={loading}
              onClick={handleClick}
              typographyProps={{
                style: { paddingLeft: "3rem", paddingRight: "3rem" },
              }}
              className={classes.blackButton}
            >
              Next
            </YZButton>
          </Box>
          <YZTypography variant="caption">
            &#42;Must be a new email newsletter signup. Cannot be combined with
            other promotions. Not valid on My Lowe's Rewards, Starter Packages,
            Botanical Packages, or gift cards.
          </YZTypography>
        </DesignProfileLayout>
      </Box>
    </DesignProfileRouteAccess>
  );
};
